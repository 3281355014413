const PI2 = Math.PI * 2;
const COLORS = [
  // {r: 128, g: 46, b: 82}, // #802E52 dark purple
  // {r: 243, g: 147, b: 37}, // #F39325 yellow
  // {r: 86, g: 32, b: 41}, // #562029 dark red
  // {r: 173, g: 47, b: 82}, // #AD2F52 darker pink
  {r: 204, g: 54, b: 95}, // #CC365F pink
  // #CC365F
]

class Particle {
  constructor(x, y, radius, rgb, i) {
    this.index = i
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.rgb = rgb;
    this.vx = Math.random() * 2.5 / 4;
    this.vy = Math.random() * .5 / 4;
    this.sinValue = Math.random() * 2;
  }

  animate(ctx, stageWidth, stageHeight) {
    this.sinValue += 0.01;
    this.radius += this.index % 2 ? Math.sin(this.sinValue) : Math.cos(this.sinValue);
    this.x += this.vx;
    this.y += this.vy / 2;

    if (this.x < 0) {
      this.vx *= -1;
      this.x += 20;
    } else if (this.x > stageWidth) {
      this.vx *= -1;
      this.x -= 20;
    }

    if (this.y < 0) {
      this.vy *= -1;
      this.y += 15;
    } else if (this.y > stageHeight) {
      this.vy *= -1;
      this.y -= 15;
    }

    ctx.beginPath();

    const g = ctx.createRadialGradient(
      this.x,
      this.y,
      this.radius * .01,
      this.x,
      this.y,
      this.radius
    );
    g.addColorStop(0, `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, 1)`);
    g.addColorStop(.8, `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, .1)`);
    g.addColorStop(1, `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, 0)`);
    ctx.fillStyle = g;

    // ctx.fillStyle = `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, 1)`
    ctx.arc(this.x, this.y, this.radius, 0, PI2, false);
    ctx.fill();
  }
}

class GradientBg extends HTMLElement {
  constructor() {
    super();

    this.canvas = document.createElement('canvas');
    const style = document.createElement('style');
    style.innerHTML = ':host {display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%;} @media(max-width: 768px) {canvas {opacity: 0}} canvas {display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0;}'

    this._shadowRoot = this.attachShadow({mode: 'open'});
    this._shadowRoot.appendChild(this.canvas);
    this._shadowRoot.appendChild(style);
  }

  resize() {
    this.stageWidth = this.getBoundingClientRect().width;
    this.stageHeight = this.getBoundingClientRect().height;
    this.canvas.width = this.stageWidth;
    this.canvas.height = this.stageHeight;
    // this.ctx.globalCompositeOperation = 'saturation';

    this.createParticles();
  }

  createParticles() {
    let curColor = 0;

    for (let i = 0; i < this.totalParticles; i++) {
      const item = new Particle(
        !i ? 0 : this.stageWidth / (this.totalParticles - i),
        !i ? this.stageHeight : this.stageHeight * (i / this.totalParticles),
        Math.random() * (this.maxRadius - this.minRadius) + this.minRadius,
        COLORS[curColor],
        i
      );

      // if (++curColor >= COLORS.length) {
      //   curColor = 0;
      // }

      this.particles[i] = item;
    }

    this.particles[this.totalParticles] = new Particle(
      this.stageWidth,
      100,
      Math.random() * (this.maxRadius - this.minRadius) + this.minRadius,
      {r: 204, g: 54, b: 95},
      this.totalParticles
    );
  }

  animate() {
    window.requestAnimationFrame(this.animate.bind(this));

    this.ctx.clearRect(0, 0, this.stageWidth, this.stageHeight);
    for (let i = 0; i < this.totalParticles + 1; i++) {
      this.particles[i].animate(this.ctx, this.stageWidth, this.stageHeight)
    }
  }

  connectedCallback() {
    this.ctx = this.canvas.getContext('2d');

    this.particles = [];
    this.maxRadius = 600;
    this.minRadius = 350;
    this.totalParticles = 5;
    //
    window.addEventListener('resize', this.resize.bind(this), false);
    this.resize();
    this.animate();
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.resize.bind(this), false);
  }
}

customElements.define('gradient-bg', GradientBg);
