const selectors = {
  up: 'is-scrolled-up',
  down: 'is-scrolled-down',
}

let lastScrollY = window.scrollY

window.addEventListener('scroll', () => {
  const scrollY = window.scrollY;

  if (document.body.classList.contains('is-loading')) return

  if (lastScrollY > 200) {
    document.body.classList.toggle(selectors.up, scrollY < lastScrollY)
    document.body.classList.toggle(selectors.down, scrollY > lastScrollY)
  } else {
    document.body.classList.remove(selectors.up)
    document.body.classList.remove(selectors.down)
  }

  lastScrollY = scrollY > 0 ? scrollY : 0;
}, {passive: true})
