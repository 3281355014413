const initReveals = (container) => {
  container.querySelectorAll('[data-reveal]').forEach(el => {
    let io = new IntersectionObserver((entries, observer) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        el.classList.add('is-revealed')
        io.unobserve(el)
      }
    })

    io.observe(el)
  })
}

export {initReveals}
