const selectors = {
  class: '.wl-select',
  isActive: 'is-active',
  isSelected: 'is-selected',
  item: '[data-value]',
}

class WlSelect extends HTMLElement {
  constructor() {
    super();

    this.isActive = false;

    this.$select = this.querySelector('select');
    this.$label = this.querySelector('div');

    this.addEventListener('click', this.showSelect);
    window.addEventListener('click', this.hideSelect.bind(this));
  }

  showSelect(e) {
    if (e.target === this) {
      this.classList.add(selectors.isActive)
      this.isActive = true;
    } else {
      const $value = e.target;
      this.$label.innerHTML = $value.innerHTML;
      this.$select.value = $value.dataset.value;
      this.$select.dispatchEvent(new Event('change', {bubbles: true}));

      this.hideSelect()
    }
  }

  hideSelect(e) {
    if (!this.isActive || e.target === this) return;

    this.classList.remove(selectors.isActive)
    this.isActive = false;
  }
}

customElements.define('wl-select', WlSelect);
