import {toggleTab} from "wildlabs-frontend-core/js/tabs.js";

class FormsComponent extends HTMLElement {
  constructor() {
    super();

    this.search = this.querySelector('[data-search]')
    this.items = this.querySelectorAll('[data-item]')
    this.$select = this.querySelector('select')
    this.$tabContainers = this.querySelectorAll('div[data-tab]')

    this.search.addEventListener('keyup', this.searchItems.bind(this))

    this.$select && this.$select.addEventListener('change', this.switchCatOnSelect.bind(this))
  }

  connectedCallback() {
    if (document.body.classList.contains('archive')) {
      const term = [...document.body.classList].find(n => n.indexOf('term-') === 0).replace('term-', '')

      term && toggleTab(['forms', term])
    }
  }

  switchCatOnSelect() {
    toggleTab(this.$select.value.split('|'))
  }

  searchItems() {
    // filter items based on search input
    const searchValue = this.search.value.toLowerCase()

    this.items.forEach(item => {
      const itemText = item.dataset.item

      if (itemText.includes(searchValue)) {
        item.style.display = 'block'
      } else {
        item.style.display = 'none'
      }
    })

    this.$tabContainers.forEach(tabContainer => {
      tabContainer.classList.toggle('has-no-results', tabContainer.getBoundingClientRect().height < 50)

    });
  }

  disconnectedCallback() {
    this.search.removeEventListener('keyup', this.searchItems)
    this.$select && this.$select.removeEventListener('change', this.switchCatOnSelect)
  }
}

customElements.define('forms-component', FormsComponent);
