class WlPosts extends HTMLElement {
  constructor() {
    super();

    this.cat = '';
    this.searchQuery = '';
    this.$select = this.querySelector('select')
    this.$cats = this.querySelectorAll('[data-cat]')
    this.$itemsContainer = this.querySelector('[data-items]')
    this.$loadMore = this.querySelector('[data-more]')
    this.$searchInput = this.querySelector('[data-search]')
    this.$searchTreshhold = null

    this.$cats.forEach($cat => {
      $cat.addEventListener('click', this.switchCat.bind(this))
    })

    this.$select && this.$select.addEventListener('change', this.switchCatOnSelect.bind(this))

    this.$searchInput.addEventListener('keyup', this.search.bind(this))

    this.initLoadMore()
  }

  switchCatOnSelect() {
    this.cat = this.$select.value
    this.loadResults()
  }

  switchCat(e) {
    this.cat = e.target.dataset.cat
    this.$cats.forEach($el => $el.classList.toggle('is-active', $el.dataset.cat === this.cat))

    this.loadResults()
  }

  loadResults() {
    this.$itemsContainer.classList.add('is-loading')
    fetch(`${location.href}${this.cat}?s=${this.searchQuery}`).then(res => res.text()).then(text => {
      const html = new DOMParser().parseFromString(text, 'text/html')
      this.$itemsContainer.innerHTML = html.querySelector('[data-items]').innerHTML
      this.$loadMore = this.querySelector('[data-more]')
      this.$itemsContainer.classList.remove('is-loading')
      this.initLoadMore()
    })
  }

  initLoadMore() {
    this.$loadMore && this.$loadMore.addEventListener('click', this.loadMore.bind(this))
  }

  loadMore() {
    fetch(`${this.$loadMore.dataset.more}?s=${this.searchQuery}`).then(res => res.text()).then(text => {
      const html = new DOMParser().parseFromString(text, 'text/html')
      this.$loadMore.remove()
      this.$itemsContainer.innerHTML += html.querySelector('[data-items]').innerHTML
      this.$loadMore = this.querySelector('[data-more]')
      this.initLoadMore()
    })
  }

  search() {
    clearTimeout(this.$searchTreshhold)
    this.$searchTreshhold = setTimeout(() => {
      this.searchQuery = this.$searchInput.value
      this.loadResults()
    }, 500)
  }

  disconnectedCallback() {
  }
}

customElements.define('wl-posts', WlPosts);
