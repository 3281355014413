class WlSearchPage extends HTMLElement {
  constructor() {
    super();

    this.page = 1;
    this.searchQuery = '';
    this.$searchInput = this.querySelector('[data-search]')
    this.$resultContainer = this.querySelector('[data-result]')
    this.$loadMore = this.querySelector('[data-more]')
    this.$searchTreshhold = null
    this.$searchInput.addEventListener('keyup', this.search.bind(this))

    this.initLoadMore()
  }

  loadResults() {
    this.page = 1;

    this.makeQuery().then(html => {
      this.$resultContainer.innerHTML = html.querySelector('[data-result]').innerHTML

      this.initLoadMore()
    })
  }

  initLoadMore() {
    this.$itemsContainer = this.querySelector('[data-items]')
    this.$loadMore = this.querySelector('[data-more]')
    this.$progress = this.querySelector('[data-progress]')
    this.$loadMore && this.$loadMore.addEventListener('click', this.loadMore.bind(this))
  }

  loadMore() {
    this.page += 1;

    this.makeQuery().then(html => {
      this.$loadMore.remove()
      this.$progress.remove()
      this.$itemsContainer.innerHTML += html.querySelector('[data-items]').innerHTML

      this.initLoadMore()
    })
  }

  async makeQuery() {
    const response = await fetch(`${location.href}?q=${this.searchQuery}&qp=${this.page}`)
    const text = await response.text()

    return new DOMParser().parseFromString(text, 'text/html')
  }

  search() {
    clearTimeout(this.$searchTreshhold)
    this.$searchTreshhold = setTimeout(() => {
      this.searchQuery = this.$searchInput.value
      this.loadResults()
    }, 500)
  }

  connectedCallback() {
    this.$searchInput.focus()
  }

  disconnectedCallback() {
    this.$searchInput.removeEventListener('keyup', this.search)
    this.$loadMore && this.$loadMore.removeEventListener('click', this.loadMore)
  }
}

customElements.define('wl-search-page', WlSearchPage);
