class WlFormComponent extends HTMLElement {
  constructor() {
    super();

    this.iframe = this.querySelector('iframe')
    this.input = this.querySelector('input')
    this.btn = this.querySelector('button')
    this.btn && this.btn.addEventListener('click', this.termsAgree.bind(this))

    window.addEventListener("message", this.onIframeMessage.bind(this), false);
  }

  onIframeMessage(e) {
    if (typeof e.data === 'object' || !['https://form.jotform.com', 'https://submit.jotform.com'].includes(e.origin)) {
      return;
    }
    const args = e.data.split(":");
    switch (args[0]) {
      case "scrollIntoView":
        this.iframe.scrollIntoView();
        break;
      case "setHeight":
        this.iframe.style.height = (parseInt(args[1])) + 100 + "px";
        if (!isNaN(args[1]) && parseInt(this.iframe.style.minHeight) > parseInt(args[1])) {
          this.iframe.style.minHeight = (parseInt(args[1])) + 100 + "px";
        }
        break;
      case "collapseErrorPage":
        if (this.iframe.clientHeight > window.innerHeight) {
          this.iframe.style.height = window.innerHeight + "px";
        }
        break;
      case "reloadPage":
        window.location.reload();
        break;
    }

    if(e.origin === 'https://submit.jotform.com') {
      window.scrollTo(0, this.offsetTop)
    }
  }

  termsAgree() {
    if (this.input.checked) {
      this.classList.remove('has-terms')
      this.classList.remove('has-error')
      window.scrollTo(0, this.offsetTop)
    } else {
      this.classList.add('has-error')
    }
  }

  connectedCallback() {
  }

  disconnectedCallback() {
    this.btn && this.btn.removeEventListener('click', this.termsAgree)
    window.removeEventListener("message", this.onIframeMessage);
  }
}

customElements.define('wl-form-component', WlFormComponent);
