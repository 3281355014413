class AnchorComponent extends HTMLElement {
  constructor() {
    super();
    if (location.hash) {
      const el = document.querySelector(`${location.hash}`)

      if (el === this) {
        setTimeout(() => {
          window.scrollTo({
            top: el.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        }, 200)
      }
    }
  }

  connectedCallback() {
    // document.querySelector(`a[href$="#${this.id}"]`).addEventListener('click', (e) => {
    //   e.preventDefault()
    //   window.scrollTo({
    //     top: this.offsetTop,
    //     left: 0,
    //     behavior: 'smooth'
    //   })
    // })
  }

  disconnectedCallback() {
  }
}

customElements.define('anchor-component', AnchorComponent);
