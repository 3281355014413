class WlQuiz extends HTMLElement {
  constructor() {
    super();

    this.selectedOptions = []
    this.data = JSON.parse(this.getAttribute('data'));
    this.btn = this.innerHTML
    this.addEventListener('click', this.onClick.bind(this));
    window.addEventListener('click', this.onOutsideClick.bind(this));
  }

  connectedCallback() {
    this.connections = {}
    let prevLine = []

    for (const id in this.data.lines) {
      if (this.data.lines[id].join('').length) {
        this.data.lines[id].forEach((line, index) => prevLine[index] = line.length ? line.trim() : prevLine[index])
        this.connections[id] = [...prevLine]
      }
    }

    this.generateLines()
  }

  onClick(e) {
    const {target} = e;

    if (target.matches('[data-value]')) {
      this.closeAllSelects();
      target.parentElement.classList.add('is-active');
    }

    if (target.matches('li')) {
      const value = target.innerText.trim();
      const $ul = target.closest('ul');
      const index = Number($ul.dataset.index);

      if (index < this.selectedOptions.length && this.selectedOptions[index] !== value) {
        this.selectedOptions.length = index;
      }

      target.closest('div').classList.remove('is-active');
      $ul.previousElementSibling.innerText = value;
      this.selectedOptions[index] = value;

      this.generateLines();
    }
  }

  prepareString(string, options, i) {
    if (string.includes('{option}')) {
      const optionsList = options.map(o => '<li>' + o + '</li>').join('');
      const option = !this.selectedOptions.length ? `<ul data-initial>${optionsList}</ul>` : this.selectedOptions[i] || ''

      if (!this.selectedOptions.length) {
        let i = 0
        let $initialOptions = null

        setTimeout(() => {
          $initialOptions = this.querySelectorAll('[data-initial] li');
          this.querySelector('[data-initial] li').classList.add('is-active');
        }, 500)

        this.initialListInterval = setInterval(() => {
          $initialOptions[i].classList.remove('is-active');
          i = (i + 1) % $initialOptions.length;
          $initialOptions[i].classList.add('is-active');
        }, 2000);
      } else {
        clearInterval(this.initialListInterval);
      }

      return `<div class="wl-quiz__line">${string.replace('{option}', `&nbsp;<div class="wl-quiz__select" data-select><div data-value>${option}</div><ul data-index="${i}">${optionsList}</ul></div>&nbsp;`)}</div>`;
    }

    const [url, label] = string.split('|')
    let btn = this.btn
    btn = btn.replace('{href}', url).replace('{label}', label || 'Let\'s go').replace('{target}', url.includes('http') ? '_blank' : '_self')

    return `<div class="wl-quiz__result">${btn}</div>`;
  }

  getMainLine() {
    const options = [...new Set(Object.values(this.connections).map(n => n[0]))];

    return this.prepareString(this.data.mainLine, options, 0)
  }

  getLines() {
    let lines = [];
    let exactLine = ''

    this.selectedOptions.forEach((selectedOption, selectedOptionIndex) => {
      let filteredLines = {}
      let options = []

      for (const id in this.connections) {
        const line = this.connections[id]

        if (line[selectedOptionIndex * 2] === selectedOption) {
          filteredLines[id] = line
        }

        if (this.selectedOptions.every((o, i) => line[i * 2] === o)) {
          exactLine = line[(selectedOptionIndex + 1) * 2 - 1];
        }
      }

      for (const id in filteredLines) {
        options.push(filteredLines[id][(selectedOptionIndex + 1) * 2])
      }

      options = [...new Set(options.filter(n => n))]

      lines.push(this.prepareString(options.length ? Object.values(filteredLines)[0][(selectedOptionIndex + 1) * 2 - 1] : exactLine, options, selectedOptionIndex + 1))
    });

    return lines.join('')
  }

  generateLines() {
    this.innerHTML = this.getMainLine() + this.getLines()
  }

  onOutsideClick(e) {
    if (!e.target.closest('[data-select]')) {
      this.closeAllSelects()
    }
  }

  closeAllSelects() {
    this.querySelectorAll('[data-select].is-active').forEach(el => el.classList.remove('is-active'));
  }

  disconnectedCallback() {
    window.removeEventListener('click', this.onOutsideClick);
  }
}

customElements.define('wl-quiz', WlQuiz);
