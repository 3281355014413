import {getCookie, setCookie} from "@@/js/utils/cookies.js";
import {delay} from "@@/js/utils/delay";

class AnnouncementBar extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    delay(10).then(() => {
      this.uniqid = this.attributes.uniqid.value
      if (getCookie('announcement') !== this.uniqid) {
        this.querySelector('[data-close]').addEventListener('click', this.close.bind(this))
        this.observer = new IntersectionObserver((entries, observer) => {
          if (!document.body.classList.contains('is-nav-active')) {
            document.body.classList.toggle('is-announcement-visible', entries[0].isIntersecting)
          }
        })

        this.observer.observe(this)
      }
    })
  }

  close() {
    setCookie('announcement', this.uniqid)
    document.body.classList.remove('has-announcement')
    document.documentElement.classList.add('has-announcement-closed')
  }

  disconnectedCallback() {
    this.observer?.unobserve(this)
  }
}

customElements.define('announcement-bar', AnnouncementBar);
