const template = document.createElement("template");

template.innerHTML = `
<style>
:host {
	padding: var(--dialog-container-padding, 5vw 24px);
	z-index: var(--dialog-z-index, 12345678);
}

:host,
#backdrop {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

:host,
:host([center]) #dialog {
  z-index: 9999;
	overflow-x: var(--dialog-overflow-x, hidden);
	overflow-y: var(--dialog-overflow-y, auto);
	display: var(--dialog-display, grid);
	align-items: var(--dialog-align-items, center);
	overflow: auto;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
}

:host(:not([open])),
:host(:not(:defined)) {
	display: none;
}

#backdrop {
	background: var(--dialog-backdrop-bg, rgba(0, 0, 0, 0.6));
	animation: fadeIn var(--dialog-animation-duration, 100ms) var(--dialog-animation-easing, ease-out);
	z-index: -1;
}

#dialog {
	animation: scaleIn var(--dialog-animation-duration, 100ms) var(--dialog-animation-easing, ease-out);
	border-radius: var(--dialog-border-radius, 12px);
	box-shadow: var(--dialog-box-shadow, 0 2px 10px -5px rgba(0, 0, 0, 0.6));
	max-width: var(--dialog-max-width, 700px);
	width: var(--dialog-width, 100%);
	padding: var(--dialog-padding, 24px);
	max-height: var(--dialog-max-height, unset);
	height: var(--dialog-height, auto);
	color: var(--dialog-color, currentColor);
	background: var(--dialog-bg, white);
	z-index: 1;
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	border: none;
}

@keyframes scaleIn {
	0% {
		transform: scale(0.9) translateY(30px);
		opacity: 0;
	}
	100% {
		transform: scale(1) translateY(0);
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
  <div id="backdrop" part="backdrop"></div>
  <div id="dialog" part="dialog">
    <slot></slot>
  </div>
`;

class WlModal extends HTMLElement {
  static get observedAttributes() {
    return ["open"];
  }

  get open() {
    return this.hasAttribute("open");
  }

  set open(value) {
    value ? this.setAttribute("open", "") : this.removeAttribute("open");
  }

  constructor() {
    super();

    const shadow = this.attachShadow({mode: "open"});
    shadow.appendChild(template.content.cloneNode(true));

    this.$backdrop = shadow.querySelector("#backdrop")
  }

  connectedCallback() {
    this.$backdrop.addEventListener("click", this.onBackdropClick.bind(this));
  }

  disconnectedCallback() {
    this.$backdrop.removeEventListener("click", this.onBackdropClick);

    if (this.open) {
      this.didClose();
    }
  }

  toggle() {
    this.open = !this.open;
  }

  show() {
    this.open = true;
  }

  close() {
    this.open = false;
  }

  onBackdropClick() {
    this.close();
  }

  onKeyDown(e) {
    switch (e.code) {
      case "Escape":
        this.close();
        e.stopImmediatePropagation();
        break;
    }
  }

  didOpen() {
    this.tabIndex = 0;
    document.body.classList.add("modal-is-open");
    this.addEventListener("keydown", this.onKeyDown, {capture: true, passive: true});
    this.dispatchEvent(new CustomEvent("open"));
  }

  didClose() {
    document.body.classList.remove("modal-is-open");
    this.removeEventListener("keydown", this.onKeyDown, {capture: true});
    this.tabIndex = -1;
    if (this.$previousActiveElement != null) {
      this.$previousActiveElement.focus();
      this.$previousActiveElement = null;
    }

    this.dispatchEvent(new CustomEvent("close", {detail: {}}));
  }

  attributeChangedCallback(name, newValue, oldValue) {
    switch (name) {
      case "open":
        this.open ? this.didOpen() : this.didClose();
        break;
    }
  }
}

customElements.define('wl-modal', WlModal);

customElements.define('wl-modal-toggle', class extends HTMLElement {
  constructor() {
    super();

    this.addEventListener("click", this.onClick.bind(this));
  }

  onClick() {
    const $modal = document.querySelector(`#${this.getAttribute("target")}`);

    if ($modal == null) {
      return;
    }

    $modal.toggle();
  }

  disconnectedCallback() {
    this.removeEventListener("click", this.onClick);
  }
});
