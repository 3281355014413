import {delay} from "@@/js/utils/delay.js";

class SubNav extends HTMLElement {
  constructor() {
    super();

    const slider = this.querySelector('swiper-container')



    delay(200).then(() => {
      slider.swiper.slideTo(slider.attributes['initial-slide'].value, 0, false);

      slider.swiper.slides.forEach(slide => {
        slide.classList.add('is-visible')
        new IntersectionObserver((entries) => {
          slide.classList.toggle('is-visible', entries[0].isIntersecting)
        }, {
          threshold: .95
        }).observe(slide)
      })

      // slider.swiper.params.breakpoints =  {
      //   1200: {
      //     slidesPerGroup: 3,
      //   },
      // }
      // slider.swiper.update()
    }).then(() => delay(100)).then(() => {
      slider.classList.add('is-init')
    })
  }

  disconnectedCallback() {
  }
}

customElements.define('sub-nav', SubNav);
