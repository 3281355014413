class LazyIframe extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const iframe = this.querySelector('iframe')

    this.io = new IntersectionObserver((data) => {
      if (data[0].isIntersecting) {
        iframe.src = this.attributes.src.value
        this.io.unobserve(iframe)
      }
    })

    this.io.observe(iframe)
  }

  disconnectedCallback() {
  }
}

customElements.define('lazy-iframe', LazyIframe);
