class WlToggleClass extends HTMLElement {
  constructor() {
    super();

    this.toggledClass = this.attributes.toggle?.value || 'is-active'
    this.isToParent = this.attributes.parent
    this.isToSelf = this.attributes.self
    this.isHover = this.attributes.hover
    this.uniq = this.attributes.uniq?.value

    if (this.isHover) {
      this.addEventListener('mouseover', this.toggleClass)
    } else {
      this.onclick = this.toggleClass
    }
  }

  toggleClass() {
    if (this.uniq) {
      if (this.isToParent) {
        if (this.isToParent.value) {
          document.querySelectorAll(`[uniq="${this.uniq}"]`).forEach(el => {
            el !== this && el.closest(this.isToParent.value).classList.remove(this.toggledClass)
          })
        } else {
          this.parentElement.classList.remove(this.toggledClass)
        }
      } else {
        document.querySelectorAll(`[uniq="${this.uniq}"]`).forEach(el => el !== this && el.classList.remove(this.toggledClass))
      }
    }

    if (this.isToParent) {
      if (this.isToParent.value) {
        this.closest(this.isToParent.value).classList.toggle(this.toggledClass)
      } else {
        this.parentElement.classList.toggle(this.toggledClass)
      }
    } else if (this.isToSelf) {
      this.classList.toggle(this.toggledClass)
    } else {
      document.body.classList.toggle(this.toggledClass)
    }
  }

  disconnectedCallback() {
  }
}

customElements.define('wl-toggle-class', WlToggleClass);
