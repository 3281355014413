const selectors = {
  isActive: 'is-active'
}

class WlTabsToggle extends HTMLElement {
  constructor() {
    super();
    this.isHover = this.attributes.hover
    this.addEventListener(this.isHover ? 'mouseover' : 'click', this.toggleTab)
  }

  toggleTab(e) {
    const t = this.attributes.tab.value.split('|')
    const tabFamily = t[0]
    const tabID = t[1]

    const
      activeTab = document.querySelector(`div.${selectors.isActive}[tab^="${tabFamily}"]`),
      event = new CustomEvent('tab:close', {
        cancelable: true,
        bubbles: true,
      })

    if (activeTab.dispatchEvent(event)) {
      const
        targetTab = document.querySelector(`div[tab="${tabFamily}|${tabID}"]`),
        event = new CustomEvent('tab:open', {
          cancelable: true,
          bubbles: true,
        })

      if (targetTab.dispatchEvent(event)) {
        document.querySelectorAll(`[tab^="${tabFamily}"]`).forEach(el => el.classList.toggle(selectors.isActive, el.matches(`[tab="${tabFamily}|${tabID}"]`)))
      }
    }
  }

  disconnectedCallback() {
    this.removeEventListener(this.isHover ? 'mouseover' : 'click', this.toggleTab)
  }
}

customElements.define('wl-tabs-toggle', WlTabsToggle);
