import '../css/style.scss'
import './components/wl-toggle-class.js'
import './components/announcement'
import './components/gradient-bg'
import './components/swiper-element'
import './components/lazy-iframe'
import './components/wl-form-component'
import './components/forms-component'
import './components/anchor-component'
import './components/wl-search-page'
import './components/wl-modal'
import './components/wl-posts'
import './components/wl-tabs'
import './components/keen-slider'
import './components/wl-select'
import './components/wl-quiz'
import './components/sub-nav'
import './components/header'
import './components/icp-discount'
import {initReveals} from './components/reveals'
import "wildlabs-frontend-core/js/utils.js";
import "wildlabs-frontend-core/js/accordion.js";
import "wildlabs-frontend-core/js/tabs.js";
import Swup from 'swup';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';

history.scrollRestoration = "manual";

if (location.hash) {
  const el = document.querySelector(`${location.hash}`)
  if (el.matches('details')) {
    el.querySelector('summary').click()
    window.scrollTo(0, el.offsetTop - 150)
  }
}

if (innerWidth < 768) {
  const modal = document.querySelector('[target="popup"]')
  modal && setTimeout(() => modal.click(), 4000)
}

document.addEventListener('click', () => document.body.classList.remove('is-tab-pressed'))
document.addEventListener('keyup', (e) => {
  e.keyCode === 9 && document.body.classList.add('is-tab-pressed')
})

const swup = new Swup({
  plugins: [
    new SwupPreloadPlugin({
      preloadVisibleLinks: true
    }),
    new SwupBodyClassPlugin(),
    new SwupScrollPlugin()]
});

swup.hooks.on('visit:start', (visit) => {
  document.body.classList.remove('is-nav-active')
});

['page:view', 'enable'].forEach(event => {
  swup.hooks.on(event, (visit) => {
    initReveals(document.body)

    if (document.querySelector('#elmo-recruitment-embed')) {
      iFrameResize && iFrameResize({heightCalculationMethod: 'max'}, '#elmo-recruitment-embed')
    }
  });
})
