class IcpDiscount extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    let script = document.createElement('script');
    script.src = 'https://cpl-widgets.s3.ap-southeast-2.amazonaws.com/discount/index.js';
    this.closest('main').appendChild(script);
  }

  disconnectedCallback() {
  }
}

customElements.define('icp-discount', IcpDiscount);
